@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;500;600;700&display=swap');



  .about-bg-img {
    background-image: url('./assets/about_bg_icon.png');
    }

  .projects-bg-img {
      background-image: url('https://images.unsplash.com/photo-1488972685288-c3fd157d7c7a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80');
   }

   .project-item-bg-img {
    background-image: url('https://images.unsplash.com/photo-1511818966892-d7d671e672a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80');
 }
  





:root {
  --primary: #307fc0;
}


.slick-slide > div {
  margin: 0 5px;
}
.slick-list {
  margin: 0 10px;
}

/* styles.css */
.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  color: #307fc0;
  font-size: 20px; /* Increase the font size to make the arrows larger */
  line-height: 1;
}

.slick-dots li button:before {
  color: #307fc0; /* Set the desired color for the dots */
  display: none;
}


.card {
  border: 2px solid #307fc0;
  background: black;
  border-radius: 8px;
  overflow: hidden;

  color: #fff;
  cursor: pointer;
}

.card-top h1 {
  font-size: 0.9rem;
}

/* .card-top > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: black;
} */




